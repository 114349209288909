$black: #2b2b2b;
$gray: #555555;
$green: #a0c40c;
$grayLight: #EDF2F7;
$grayDark: #6d6d6d;

/* arimo-regular - latin */
@font-face {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/arimo-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Arimo Regular'), local('Arimo-Regular'),
    url('/fonts/arimo-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/arimo-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/arimo-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/arimo-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/arimo-v13-latin-regular.svg#Arimo') format('svg'); /* Legacy iOS */
    font-display: swap;
}

/* arimo-700 - latin */
@font-face {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/arimo-v13-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Arimo Bold'), local('Arimo-Bold'),
    url('/fonts/arimo-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/arimo-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/arimo-v13-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/arimo-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/arimo-v13-latin-700.svg#Arimo') format('svg'); /* Legacy iOS */
    font-display: swap;
}

/* arimo-italic - latin */
@font-face {
    font-family: 'Arimo';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/arimo-v13-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Arimo Italic'), local('Arimo-Italic'),
    url('/fonts/arimo-v13-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/arimo-v13-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/arimo-v13-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/fonts/arimo-v13-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/arimo-v13-latin-italic.svg#Arimo') format('svg'); /* Legacy iOS */
    font-display: swap;
}

/* arimo-700italic - latin */
@font-face {
    font-family: 'Arimo';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/arimo-v13-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Arimo Bold Italic'), local('Arimo-BoldItalic'),
    url('/fonts/arimo-v13-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/arimo-v13-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/arimo-v13-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('/fonts/arimo-v13-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/arimo-v13-latin-700italic.svg#Arimo') format('svg'); /* Legacy iOS */
    font-display: swap;
}

@import "statusbar.css";

html {
    scroll-behavior: smooth;
}

.text-right {
    text-align: right;
}
.text-bold {
    font-weight: bold;
}
.text-green {
    color: $green;
}
.no-underline {
    text-decoration: none;
}
.hover\:underline:hover {
    text-decoration: underline;
}
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.w-full {
    width: 100%;
}
.flex {
    display: flex;
}
.grid {
    display: grid;
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-gap {
    grid-gap: 10px;
}
.flex-w1\/2 > div {
    width: 50%;
}
.justify-between {
    justify-content: space-between;
}

body {
    font-family: 'Arimo', sans-serif;
    font-size: 1rem;
}

#page {
    margin: 0 auto;
    width: 741px;
}

.wrapper {
    margin-top: 19px;
}
.logo {
    margin-left: 22px;
}
.menu-spacer {
    background-color: $green;
    height: 35px;
}

.navigation {
    background-color: $black;
    display: grid;
    grid-template-columns: 1fr 551px;
    height: 135px;
    margin-top: 1px;
}
.navbg {
    background-repeat: no-repeat;
}
.no-webp .navbg {
    background-image: url("/images/header_photo.png");
}
.webp .navbg {
    background-image: url("/images/header_photo.webp");
}

nav {
    padding: 5px 15px;
}

nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: flex-end;
    justify-items: center;
    height: 100%;
}

nav ul li {
    flex: 1;
}

nav a {
    text-align: right;
    text-decoration: none;
    width: 170px;
    height: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

nav a:hover {
    color: $grayLight;
}

.content-wrapper {
    display: grid;
    grid-template-columns: 188px 1fr;
    border-color: $black;
    border-width: 0 1px;
    border-style: solid;
}

.sidebar {
    min-height: 200px;
    background-repeat: no-repeat;
    background-position: top 10px right 10px;
    border-right: 1px solid $black;
    padding: 75px 10px 0;
}
.no-webp .sidebar {
    background-image: url("/images/side_arrow.png");
}
.webp .sidebar {
    background-image: url("/images/side_arrow.webp");
}

.content {
    padding: 10px;
}

.content a {
    color: inherit;
}
.content ul {
    list-style: square;
}
.content img {
    max-width: 100%;
    height: auto;
}

.footer {
    display: flex;
    justify-content: center;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;

    color: #fff;
    font-weight: normal;
    text-align: center;
    font-size: 0.8rem;
    width: 100%;
    background-color: $grayDark;
    padding: 2px;
}
.footer strong { padding-right: 10px; }
.footer ul {
    display: flex;
    align-self: center;
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer ul li {
    font-weight: 700;
    padding: 0 3px;
    font-size: 0.8rem;
}
.footer a {
    color: #dedede;
    text-decoration: none;
    font-weight: 700;
    margin: 0 1px;
}

.subfooter {
    text-align: right;
}
.subfooter a {
    text-decoration: none;
    color: #4A5568;
    font-size: 0.8rem;
}

.search-wrapper {
    padding: 30px 0;
}
.search-form {
    display: flex;
    justify-content: center;

    button {
        margin-left: 20px;
        background-repeat: no-repeat;
        background-position: top;
        width: 75px;
        height: 29px;
        border: medium none;
        color: transparent;
    }
}
.no-webp .search-form button {
    background-image: url("/images/searchbutton.png");
}
.webp .search-form button {
    background-image: url("/images/searchbutton.webp");
}

.search-form button:hover {
    background-position: bottom;
    cursor: pointer;
}

.search-form input {
    border: medium none;
    padding: 0 0 0 40px;
    margin: 0;
    width: 259px;
    height: 29px;
    line-height: 24px;
    color: #808080;
    background-repeat: no-repeat;
    background-position: top;
    font-size: 0.8rem;
}
.no-webp .search-form input {
    background-image: url("/images/searchbalk.png");
}
.webp .search-form input {
    background-image: url("/images/searchbalk.webp");
}
.search-form input:focus, .search-form input[value=""] {
    background-position: bottom;
}

.home h2, .home h3 {
    font-size: 1rem;
}

.home h3 {
    margin-top: 40px;
}

.populair {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 20px 0 10px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    ul li:before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 5px;
        margin-bottom: 5px;
        background: $black;
    }

    ul li a {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 200px;
        white-space: nowrap;
        font-size: 0.9rem;
        color: $black;
        text-decoration: none;
    }
}


.textfield {
    display: grid;
    grid-template-columns: 162px 1fr;
    gap: 10px;
    padding: 5px 0;

    label {
        display: inline-grid;
        grid-template-columns: 1fr;
        grid-auto-flow: column;
        align-content: center;
        text-align: right;
        font-weight: 700;
        font-size: 0.8rem;
    }

    input {
        display: grid;
        padding: 3px 3px;
        width: 100%;
        border: 1px solid #A5ACB2;
        align-content: center;
        font-size: 0.8rem;
    }
}

.button-zend {
    background-repeat: no-repeat;
    border: medium none;
    width: 81px;
    height: 21px;
    line-height: 21px;
    color: transparent;
}
.no-webp .button-zend { background-image: url("/images/zend.png"); }
.webp .button-zend { background-image: url("/images/zend.webp"); }

.button-wis {
    background-repeat: no-repeat;
    border: medium none;
    width: 81px;
    height: 21px;
    line-height: 21px;
    color: transparent;
}
.no-webp .button-wis { background-image: url("/images/wis.png"); }
.webp .button-wis { background-image: url("/images/wis.webp"); }

.textarea {
    display: grid;
    grid-template-columns: 162px 1fr;
    gap: 10px;
    padding: 5px 0;

    label {
        display: inline-grid;
        grid-template-columns: 1fr;
        grid-auto-flow: column;
        align-content: baseline;
        text-align: right;
        font-weight: 700;
        font-size: 0.8rem;
    }

    textarea {
        display: grid;
        padding: 3px 3px;
        width: 100%;
        min-height: 75px;
        border: 1px solid #A5ACB2;
        align-content: center;
        font-size: 0.8rem;
    }
}

.border-bottom-dotted {
    padding: 25px 0;
    border-bottom: 1px dotted $grayDark;
}
.no-padding-top {
    padding-top: 0;
}
.company-name {
    font-size: 1.25rem;
    margin: 0;
}
.company-name a {
    text-decoration: none;
}
.company-logo {
    padding-top: 10px;
    max-width: 260px;
    height: auto;
}
.company-categories {
    margin-top: 25px;

    ul {
        margin-top: 0;
    }
}
.company-info {
    margin-top: 25px;
}
.company-naw {
    margin-bottom: 20px;
}
.company-contact {
    margin-top: 25px;
    margin-bottom: 25px;
}

.company-icon {
    display: flex;
}

.social {
    margin-top: 25px;
}
.businesshours {
    margin-top: 25px;
}

.icon {
    padding-right: 10px;
}
.icon img {
    width: 21px;
    height: 21px;
}

.hidden {
    display: none;
}
.has_error {
    display: block;
    color: #C53030;
    font-size: 0.875rem;
}
.textfield input.form-error {
    border-color: #c53030;
}

.privacy {
    font-size: 12px;
    margin-bottom: 0.5rem;
}

.googlemaps {
    width: 100%;
    height: 267px;
}
.googlemaps #map {
    height: 100%;
    background-image: url("/images/maps.png");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}
.no-map {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 11px;
    padding: 10px;
    color: white;
}

.hideGoogleMaps {
    display: none;
    text-align: right;
    color: $grayDark;
    font-size: 0.75rem;
}
.hideGoogleMaps a {
    text-decoration: none;
}

.flashmessage {
    color: white;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 24px;
    padding: 3px 10px;
    font-weight: 700;
}
.notify_success {
    background-color: $green;
}
.notify_failure {
    background-color: #dc2626;
}

.company-whatsapp {
    padding-top: 5px;
}
.company-whatsapp a img {
    max-width: 134px;
}

@media(max-width: 741px) {
    #page {
        margin: 0;
        width: 100%;
    }

    .navigation {
        height: auto;
    }

    nav li {
        line-height: 28px;
    }

    .grid, .flex {
        display: block;
    }

    .company-logo {
        padding-bottom: 15px;
    }

    .navigation { display: block; }
    .navbg { display: none; }
    .content-wrapper { display: block; }
    .sidebar { display: none; }
    .search-form {
        display: block;
        text-align: right;
    }
    .search-form button {
        margin-left: 0;
        margin-top: 25px;
    }
    .populair { display: block; }
    .footer ul {
        flex-wrap: wrap;
    }
    .footer { flex-direction: column; }
    .footer ul li {
        line-height: 28px;
    }
    .subfooter { margin-right: 10px;}

    .company-categories ul li {
        line-height: 28px;
    }
    .company-icon {
        padding-bottom: 5px;
    }
}
